import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Intro from '../components/intro';
import Showcase from '../components/showcase';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Construction solutions within commercial real estate" />
    <Intro siteTitle="RSS Commercial" />
    <Showcase
      title="Landlord Cat A"
      firstImage={data.landlordCatA}
      secondImage={data.landlordCatA2}
    />
    <Showcase
      title="Landlord Cat A+"
      firstImage={data.landlordCatAPlus}
      secondImage={data.landlordCatAPlus2}
    />
    <Showcase
      title="Tenant Cat B"
      firstImage={data.tenantCatB}
      secondImage={data.tenantCatB2}
    />
    <Showcase
      firstImage={data.tenantCatB3}
      secondImage={data.tenantCatB4}
    />
    <Showcase
      firstImage={data.tenantCatB5}
      secondImage={data.tenantCatB6}
    />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    landlordCatA: PropTypes.objectOf(PropTypes.object),
    landlordCatA2: PropTypes.objectOf(PropTypes.object),
    landlordCatAPlus: PropTypes.objectOf(PropTypes.object),
    landlordCatAPlus2: PropTypes.objectOf(PropTypes.object),
    tenantCatB: PropTypes.objectOf(PropTypes.object),
    tenantCatB2: PropTypes.objectOf(PropTypes.object),
    tenantCatB3: PropTypes.objectOf(PropTypes.object),
    tenantCatB4: PropTypes.objectOf(PropTypes.object),
    tenantCatB5: PropTypes.objectOf(PropTypes.object),
    tenantCatB6: PropTypes.objectOf(PropTypes.object),
  })
};

IndexPage.defaultProps = {
  data: null,
};

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 768, maxHeight: 512, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    landlordCatA: file(relativePath: { eq: "showcase/landlord-cat-a.jpg" }) {
      ...fluidImage
    }
    landlordCatA2: file(relativePath: { eq: "showcase/landlord-cat-a-2.jpg" }) {
      ...fluidImage
    }
    landlordCatAPlus: file(relativePath: { eq: "showcase/landlord-cat-a-plus.jpg" }) {
      ...fluidImage
    }
    landlordCatAPlus2: file(relativePath: { eq: "showcase/landlord-cat-a-plus-2.jpg" }) {
      ...fluidImage
    }
    tenantCatB: file(relativePath: { eq: "showcase/tenant-cat-b.jpg" }) {
      ...fluidImage
    }
    tenantCatB2: file(relativePath: { eq: "showcase/tenant-cat-b-2.jpg" }) {
      ...fluidImage
    }
    tenantCatB3: file(relativePath: { eq: "showcase/tenant-cat-b-3.jpg" }) {
      ...fluidImage
    }
    tenantCatB4: file(relativePath: { eq: "showcase/tenant-cat-b-4.jpg" }) {
      ...fluidImage
    }
    tenantCatB5: file(relativePath: { eq: "showcase/tenant-cat-b-5.jpg" }) {
      ...fluidImage
    }
    tenantCatB6: file(relativePath: { eq: "showcase/tenant-cat-b-6.jpg" }) {
      ...fluidImage
    }
  }
`;
