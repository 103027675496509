import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Container from 'components/container';

import styles from './styles.module.scss';

const Showcase = ({ title, firstImage, secondImage }) => (
  <section className={styles.showcase}>
    <Container>
      {
        ({ title } !== '')
          && <h2>{title}</h2>
      }
      <div
        className={styles.images}
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="easeOutCubic"
      >
        <Img
          className={styles.firstImage}
          fluid={firstImage.childImageSharp.fluid}
        />
        <Img
          className={styles.secondImage}
          fluid={secondImage.childImageSharp.fluid}
        />
      </div>
    </Container>
  </section>
);

Showcase.propTypes = {
  title: PropTypes.string,
  firstImage: PropTypes.objectOf(PropTypes.object),
  secondImage: PropTypes.objectOf(PropTypes.object),
};

Showcase.defaultProps = {
  title: '',
  firstImage: {},
  secondImage: {},
};

export default Showcase;
