import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Container from 'components/container';

import styles from './styles.module.scss';

const Intro = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-full.svg" }) {
        childImageSharp {
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <section className={styles.intro}>
      <div className="ledeWrapper">
        <Container>
          <div className={styles.lede}>
            {/* svg support */}
            {(!data.file.childImageSharp && data.file.extension === 'svg')
              ? <img src={data.file.publicURL} alt={siteTitle} />
              : <Img fixed={data.file.childImageSharp.fixed} />}

            <h1>Construction solutions within commercial real&nbsp;estate.</h1>
          </div>
        </Container>
      </div>
      <Container>
        <p className={styles.anchor} id="about">RSS offer unrivalled experience and challenge the industry norm.</p>
        <p>
          Our collaborative approach results in management providing
          all the services our clients expect.
          <br />
          We promise transparency, integrity and accountability.
        </p>
        <p>
          The result is value through intimacy delivered sustainably.
        </p>
      </Container>
    </section>
  );
};

Intro.propTypes = {
  siteTitle: PropTypes.string,
};

Intro.defaultProps = {
  siteTitle: '',
};

export default Intro;
